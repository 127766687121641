
[data-admin] .v-card{padding:30px;border-radius:3px
}
[data-admin] .v-card .v-card__title--primary{background-color:#f0eeee
}
[data-admin] .v-card .v-card__title--primary h3{margin-left:5px
}
[data-admin] .v-card .v-card__title{font-weight:700;padding:5px
}
[data-admin] .v-divider{padding-bottom:20px
}
[data-admin] .vue-notification{position:fixed;top:80px;right:5px;border-radius:3px;height:60px;vertical-align:middle;width:250px;z-index:1000;padding:10px;font-weight:700
}
[data-admin] .vue-notification.warn{background:#ffb648;border-left-color:#f48a06
}
[data-admin] .vue-notification.error{background:#e54d42;border-left-color:#b82e24
}
[data-admin] .vue-notification.success{background:#8efaad;border-left-color:#99fdb6;color:#fff
}
[data-admin] .v-content{background-color:#f5f5f5;padding-top:30px!important
}
[data-admin] .container{padding-left:0
}
[data-admin] .courses h1{margin-top:0
}
[data-admin] .courses h3{margin:5px!important
}
[data-admin] .courses p{margin:0
}
[data-admin] .courses button:focus{outline:none
}
[data-admin] .courses .v-card{margin:10px 0;width:100%;padding:10px;min-height:180px;border:.6px solid #d1d1d1
}
[data-admin] .courses .v-card .v-responsive.v-image{height:150px
}
[data-admin] .courses .v-card .v-divider{margin-right:10px
}
[data-admin] .courses .v-card .v-btn:not(.status){position:absolute;bottom:0;margin-left:0;margin-top:20px;height:20px
}
[data-admin] .courses .v-card .status{height:25px;margin-left:0;font-size:10px;padding:5px 0
}
[data-admin] .courses .v-card .v-divider{height:150px
}
[data-admin] .v-navigation-drawer{width:250px!important
}
[data-admin] .v-list{padding-top:20px!important
}
[data-admin] .preview{padding:20px
}
[data-admin] .preview .theme--light.v-card{margin:3px;padding:0
}
[data-admin] .preview i{font-size:3.5em
}
[data-admin] .v-toolbar{position:-webkit-sticky;position:sticky;top:1px
}
[data-admin] .v-toolbar img{width:100px
}
[data-admin] .v-toolbar .v-btn--icon{margin-left:80px
}
[data-admin] .v-toolbar .v-toolbar__items{position:absolute;top:0;right:0
}
[data-admin] .v-toolbar .v-toolbar__items .v-btn{height:30px!important;margin:0 10px!important
}
[data-admin] .v-toolbar .v-toolbar__items img{border-radius:5px 20px 5px;width:40px;height:40px;margin-top:15px
}
[data-admin] .v-toolbar .v-toolbar__items .v-input__slot:hover,[data-admin] .v-toolbar .v-toolbar__items .v-overflow-btn:hover{background-color:transparent!important
}
[data-admin] .form .delete{margin:20px 0;float:right
}
[data-admin] .form .v-overflow-btn{margin-top:0;padding-top:0;height:40px
}
[data-admin] .form .v-card{margin:10px 0
}
[data-admin] .form .v-card .v-card__title{padding:5px
}
[data-admin] .form .v-card .v-btn{margin-top:20px
}
[data-admin] .form .v-card .q-link{display:none
}
[data-admin] .users .v-card__text{padding-top:0;padding-bottom:20px
}
[data-admin] .users h4{padding-bottom:20px
}
[data-admin] .users td{cursor:pointer
}
[data-admin] .lecture .dialog{margin-bottom:20px
}
[data-admin] .lecture .v-card{margin-bottom:30px;text-align:center
}
[data-admin] .lecture .section{background-color:#bbdefb;margin-top:20px;cursor:move
}
[data-admin] .lecture i{padding-right:20px;cursor:move
}
[data-admin] .lecture .sortable-ghost{background-color:#d3d1d1
}
[data-admin] .lecture .input{width:500px
}
[data-admin] .lecture .btnlecture ul{padding-left:0
}
[data-admin] .lecture .btnlecture li{padding:10px;list-style:none;font-size:20px;box-shadow:0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)
}
[data-admin] .lecture .btnlecture li .v-btn{margin-top:0
}
[data-admin] .lecture .btnlecture .lecture-item{background-color:#fff;cursor:move
}
[data-admin] .lecture .btnlecture .edit{float:right
}
[data-admin] .lecture .btnlecture .edit i{cursor:pointer
}
[data-admin] .lecture .btnlecture .delete{float:right;color:#ef5350
}
[data-admin] .lecture .btnlecture .delete i{cursor:pointer
}
[data-admin] .editlecture .v-list{padding:5px!important
}
[data-admin] .editlecture p{margin:20px 0
}
[data-admin] .editlecture .v-list__tile__content,[data-admin] .editlecture .v-list__tile__title{margin-left:20px
}
[data-admin] .editlecture .v-list__group{border:1px solid #d2d2d2;margin:2px 0
}
[data-admin] .salespage .v-card{margin:10px 0
}
[data-admin] .salespage .v-btn{margin-left:0
}
[data-admin] .salespage .v-expansion-panel{margin-bottom:10px
}
[data-admin] .salespage .v-expansion-panel .v-expansion-panel__header{padding-top:0
}
[data-admin] .salespage .author ul{padding-left:0
}
[data-admin] .salespage .author li{border:.5px solid #b3b3b3;padding:10px;list-style:none;margin-top:5px
}
[data-admin] .salespage .author li .edit{float:right
}
[data-admin] .salespage .author li .delete{float:right;color:red
}
[data-admin] .salespage .author .panel{border:none!important
}
[data-admin] .salespage .editor .v-card__title{padding:5px;margin-bottom:20px
}
[data-admin] .salespage .editor .trix-content{min-height:250px
}
[data-admin] .salespage .editor .v-btn{margin-top:30px;bottom:5px
}
[data-admin] .usersinfo b{position:relative;right:0
}
[data-admin] .usersinfo .delete{float:right
}
[data-admin] .school .v-card{margin:10px 0
}
[data-admin] .school p{margin-bottom:0
}
[data-admin] .school .vc-chrome{margin:5px
}
[data-admin] .school .vc-chrome .v-menu__content{max-width:200px!important;margin-left:122px;margin-top:100px!important
}
[data-admin] .school .course-list ul{padding-left:0
}
[data-admin] .school .course-list li{padding:10px;list-style:none;font-size:20px;box-shadow:0 2px 1px -1px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)
}
[data-admin] .school .course-list .course-item{background-color:#fff;cursor:move
}
[data-admin] .school .colorpalette .v-btn{margin:5px 0;border:1px solid #bebebe
}
[data-admin] .school .colorpalette .theme--light.v-btn.v-btn--disabled:not(.v-btn--icon):not(.v-btn--flat){background-color:hsla(0,0%,94.5%,.12157)!important
}
[data-admin] .school .colorpalette .color{width:20px!important
}
[data-admin] .school .colorpalette .color .v-menu__content{max-width:80px!important
}
[data-admin] .school .colorpalette .colorcode{width:80px;outline:none
}
[data-admin] .school .colorpalette .colorcode input{background-color:transparent!important
}
[data-admin] .school .colorpalette .v-text-field>.v-input__control>.v-input__slot:before{border:none
}
[data-admin] .settings .v-card{margin:10px 0
}
[data-admin] .settings img{width:100px;position:absolute;right:25px
}
[data-admin] .enroll .v-card{margin:10px 0
}
[data-admin] .block .v-card{padding:10px;margin:0 30px
}
[data-admin] .block .v-card .material-icons{position:absolute;top:5px;left:5px
}
[data-admin] .block .v-card h3{padding:0 10px
}
[data-admin] .block .v-list{padding-top:10px!important
}
[data-admin] .block .v-expansion-panel{background-color:#e6f0f1!important;margin:10px;box-shadow:0 2px 1px -5px rgba(0,0,0,.2),0 1px 1px 0 rgba(0,0,0,.14),0 1px 3px 0 rgba(0,0,0,.12)
}
[data-admin] .block .v-list__tile__content{margin-left:10px
}
[data-admin] .block .v-list__tile__title{height:auto
}
[data-admin] .block .v-expansion-panel__container,[data-admin] .block .v-expansion-panel__container .v-card{background-color:#eef7f8!important
}
[data-admin] .block .v-expansion-panel__header__icon .v-icon{position:relative
}
[data-admin] .block .bodyinput{width:100%
}
@media (max-width:660px){
[data-admin] .form{padding:5px
}
[data-admin] .form .v-card{padding:5px;margin:5px
}
[data-admin] .v-content{padding-top:60px!important
}
[data-admin] .v-content h1{display:none
}
[data-admin] .courses .v-card{padding:5px
}
[data-admin] .courses .v-card .v-btn{padding:0
}
[data-admin] .courses .v-card .v-btn .v-btn__content{font-size:10px!important;padding:3px
}
[data-admin] .lecture .btnlecture .edit{position:absolute;right:150px
}
}
.modalpublish .card-title{font-size:25px
}