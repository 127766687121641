
.syllabus[data-v-6bf66fd0]{padding-top:60px;padding-bottom:100px
}
.syllabus-section[data-v-6bf66fd0]{background-color:#fff
}
header[data-v-6bf66fd0]{position:relative;border:1px solid #ebebeb
}
.lessons[data-v-6bf66fd0]{padding:10px 0
}
.material-icons[data-v-6bf66fd0]{transition:all .3s ease-in-out;display:block;position:absolute;right:15px;top:50%;margin-top:-12px;width:25px;height:25px;border-radius:50%;-webkit-transform-origin:center center;transform-origin:center center;-webkit-transform:rotate(0);transform:rotate(0);color:#4cbad7;box-shadow:inset 0 0 0 3px #4cbad7
}
.heading[data-v-6bf66fd0]{margin:20px 0;font-size:30px;line-height:36px;color:#252525;font-weight:500
}
.opener[data-v-6bf66fd0]{display:flex;flex-direction:row;align-items:center;justify-content:flex-start;padding:15px;color:#252525;text-transform:uppercase
}
.collapsed .material-icons[data-v-6bf66fd0]{-webkit-transform-origin:center center;transform-origin:center center;-webkit-transform:rotate(180deg);transform:rotate(180deg);background-color:#4cbad7;color:#fff
}
.section-title[data-v-6bf66fd0]{color:#252525;font-size:16px
}
.section-index[data-v-6bf66fd0]{margin-right:15px;color:#4cbad7
}
.lesson[data-v-6bf66fd0]{padding-left:30px
}
.lesson-description[data-v-6bf66fd0]{color:#919191;font-size:14px
}
.lesson-heading[data-v-6bf66fd0]{color:#252525;font-weight:400;font-size:14px;display:flex;flex-direction:row;align-items:center;justify-content:flex-start;padding:10px 0;margin-bottom:0;text-transform:uppercase
}
.lesson-description[data-v-6bf66fd0]{padding-left:10px
}
.lesson-index[data-v-6bf66fd0]{margin-right:10px
}