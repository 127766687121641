
footer[data-v-16a00045]{background-color:#252525
}
footer svg[data-v-16a00045]{width:220px
}
footer .logo[data-v-16a00045]{display:inline-block;width:120px;margin-bottom:20px
}
footer hr[data-v-16a00045]{border-color:#7b7b7b
}
footer .top[data-v-16a00045]{color:#fff
}
footer .upper[data-v-16a00045]{padding:30px 15px;font-size:.84375rem;color:#fff
}
footer .upper p[data-v-16a00045]{margin:0;font-weight:300
}
footer .bottom[data-v-16a00045]{padding:15px;font-size:.75rem;color:#919191
}
footer .bottom a[data-v-16a00045]{color:#919191
}
footer .footer-top[data-v-16a00045]{padding:40px 0
}
footer .footer-top h3[data-v-16a00045]{margin:40px 0
}
footer .footer-top .resource-summary-leggi a[data-v-16a00045]:hover{color:#52bb88!important
}
footer .footer-top .resource-summary-guarda a[data-v-16a00045]:hover{color:#fc5f53!important
}
footer .footer-top .resource-summary-partecipa a[data-v-16a00045]:hover{color:#ffc000!important
}
footer .footer-top .resource-summary-focus a[data-v-16a00045]:hover{color:#7b7b7b!important
}
footer .footer-top .resource-summary-logo[data-v-16a00045]{margin-bottom:1.5rem;width:auto;height:30px
}
footer .footer-top .list-unstyled[data-v-16a00045]{padding-left:0;list-style:none
}
footer .footer-top img[data-v-16a00045]{margin-bottom:20px
}
footer .footer-top li[data-v-16a00045]{margin:5px 3px;list-style:none
}
footer .footer-top li a[data-v-16a00045]{color:#fff!important
}