
#subscriptionForm{background-color:#1e1d1d;padding:30px 0
}
#subscriptionForm .checkbox{color:#fff!important;font-size:12px
}
#subscriptionForm .subbutton{padding:.75rem 2rem!important
}
#subscriptionForm .subform{padding:20px
}
#subscriptionForm h6{font-size:2rem;color:#fff!important
}
#subscriptionForm p{color:#fff!important
}
#subscriptionForm input{margin-bottom:10px;background-color:#fff
}