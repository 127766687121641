
.teacher[data-v-0d00ad90]{margin:10px 0;display:flex;flex-direction:row;align-items:flex-start;justify-content:flex-start;padding:0 20px
}
.teacher .name[data-v-0d00ad90]{font-size:1.4em;margin-bottom:10px
}
.teacher .teacher-headline[data-v-0d00ad90]{font-weight:700;margin-bottom:10px
}
.info[data-v-0d00ad90]{margin:5px
}
.description[data-v-0d00ad90]{padding-top:5px
}
.image[data-v-0d00ad90]{width:100px;height:100px;border-radius:50%;overflow:hidden;margin-right:20px
}
img[data-v-0d00ad90]{width:100%;height:auto
}
@media (max-width:576px){
.teacher[data-v-0d00ad90]{display:flex;flex-direction:column;align-items:center;justify-content:center;padding:0
}
.image[data-v-0d00ad90]{margin:0
}
}